import {
  AdminDiscoveryPreferencesQuery,
  Admin_User_Summary_FullFragment,
  Discovery_SuccessFragment,
  L_News_Feed_Type,
  useAdminDiscoveryIsPausedQuery,
  useAdminDiscoveryPreferencesQuery,
  useAdminDiscoveryResultsQuery,
  useAdminPauseUserMutation,
  useAdminRelationshipFromSlugQuery,
  useAdminUnpauseUserMutation,
  useAdminUserSummaryFromSlugQuery,
  useNewsFeedAdminQuery,
} from "../__generated__/apollo-hooks";
import { AdminUserPicker, AdminUserTd, DateTd } from "../util/widgets";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { P, match } from "ts-pattern";
import { Spinner, SpinnerCentered, SpinnerFullScreen } from "shared-web-react/dist/widgets/spinner";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "shared-web-react/dist/widgets/floating-ui/tooltip";
import { adminRoutes, useMkImgAdminUrl } from "../util/admin-routes";
import { classNames, filterNulls, sortBy } from "shared/dist/util";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { DateTime } from "luxon";
import { H4 } from "shared-web-react/dist/widgets/text";
import React from "react";
import { SpinnerCheckbox } from "shared-web-react/dist/widgets/spinner-checkbox";
import clsx from "clsx";
import { distToLocale } from "shared-web-react/dist/util/units";
import { useGenderLookup } from "shared-web-react/dist/helpers/gender";
import { useTypedParams } from "react-router-typesafe-routes/dom";

export function AdminUserDiscoveryDebugger(): React.JSX.Element {
  const navigate = useNavigate();
  return (
    <div className="space-y-2 p-4 flex flex-col justify-stretch items-stretch max-h-full relative">
      <div className="flex-0">
        User Select:{" "}
        <AdminUserPicker
          onSelect={(e) =>
            e && navigate(adminRoutes.DISCOVERY_USER.DETAILS.buildPath({ slug: e.slug }))
          }
        />
      </div>
      <div className={classNames("flex-1 relative z-10 overflow-hidden")}>
        <Outlet />
      </div>
    </div>
  );
}

type DiscoveryItemRowProps = {
  user_id: string;
  item: Discovery_SuccessFragment["items"][0];
  myAge: number;
  myGender: string;
  myPrefs: undefined | null | AdminDiscoveryPreferencesQuery["discovery_preferences"][0];
};
function DiscoveryItemRow({
  myAge,
  myGender,
  myPrefs,
  item,
}: DiscoveryItemRowProps): React.JSX.Element {
  // const genderLookup = useGenderLookup();
  const userSummaryData = useAdminUserSummaryFromSlugQuery({
    variables: { slug: item.user.user_slug },
    skip: !!item.partner,
  });
  const relationshipData = useAdminRelationshipFromSlugQuery({
    variables: {
      fst_slug: item.user.user_slug,
      snd_slug: item.partner?.user_slug ?? "",
    },
    skip: !item.partner,
  });
  const item_id = item.partner
    ? relationshipData?.data?.relationships_union?.[0]?.id
    : userSummaryData?.data?.admin_user_summaries?.[0]?.id;
  const prefsQuery = useAdminDiscoveryPreferencesQuery({
    variables: { id: item_id! },
    skip: !item_id,
  });
  const myPrefGenders = myPrefs?.genders.map((g) => g.gender_id) ?? [];
  const thisRowsPrefs = prefsQuery?.data?.discovery_preferences?.[0];
  const genderDoesntMatch = item.partner?.gender_id
    ? !(
        myPrefGenders.includes(`${item.user.gender_id}+${item.partner!.gender_id}`) ||
        myPrefGenders.includes(`${item.partner!.gender_id}+${item.user.gender_id}`)
      )
    : !myPrefGenders.includes(item.user.gender_id);
  return (
    <tr>
      <td>
        <Link to={adminRoutes.USERS.DETAIL.buildPath({ slug: item.user.user_slug })}>
          <Avatar tailwindSize="12" slug={item.user.user_slug} />
        </Link>

        {item.partner ? (
          <Link
            to={adminRoutes.USERS.DETAIL.buildPath({
              slug: item.partner.user_slug,
            })}
          >
            <Avatar tailwindSize="12" slug={item.partner.user_slug} />
          </Link>
        ) : null}
      </td>
      <td>
        {"@" + item.user.user_slug}
        {item.partner && "/ @" + item.partner.user_slug}
      </td>
      <td
        className={classNames(
          item.user.distance_m > (myPrefs?.max_distance ?? 0) && "bg-error text-error-content"
        )}
      >
        <Tooltip>
          <TooltipTrigger>
            {distToLocale({ dist: item.user.distance_m, units: "m" })}
          </TooltipTrigger>
          {item.user.distance_m > (myPrefs?.max_distance ?? 0) && (
            <TooltipContent>Outside of users preferred distance</TooltipContent>
          )}
        </Tooltip>
      </td>
      <td className={classNames(genderDoesntMatch && "bg-error text-error-content")}>
        <Tooltip>
          <TooltipTrigger>
            {item.user.gender_id}
            {item.partner ? ` / ${item.partner.gender_id}` : ""}
          </TooltipTrigger>
          {genderDoesntMatch && <TooltipContent>gender doesnt match</TooltipContent>}
        </Tooltip>
      </td>
      <td className={classNames("whitespace-nowrap")}>
        {item.user.age}
        {item.partner ? ` / ${item.partner.age}` : ""}
      </td>
      {thisRowsPrefs && (
        <>
          <td className={classNames("max-w-[20rem] whitespace-nowrap overflow-x-auto")}>
            {prefsQuery.loading ? (
              <Spinner />
            ) : (
              <>
                <span className={classNames("font-bold underline")}>
                  {thisRowsPrefs?.genders
                    .map((g) => g.gender_id)
                    ?.filter((g) => g === myGender)
                    ?.join(", ")
                    .replace(/gender_/g, "")}
                </span>{" "}
                <span>
                  {thisRowsPrefs?.genders
                    .map((g) => g.gender_id)
                    ?.filter((g) => g !== myGender)
                    ?.join(", ")
                    .replace(/gender_/g, "")}
                </span>
              </>
            )}
          </td>
          <td
            className={classNames(myAge < thisRowsPrefs.min_age && "bg-error text-error-content")}
          >
            {prefsQuery.loading ? <Spinner /> : `${thisRowsPrefs.min_age}-${thisRowsPrefs.max_age}`}
          </td>
          <td>
            {prefsQuery.loading ? (
              <Spinner />
            ) : (
              distToLocale({ dist: thisRowsPrefs.max_distance, units: "m" })
            )}
          </td>
        </>
      )}
    </tr>
  );
}

export function AdminDiscoveryForUserScreen(): React.JSX.Element {
  const slug = useTypedParams(adminRoutes.DISCOVERY_USER.DETAILS).slug;
  return (
    <div className="space-y-2 pt-4 flex flex-col justify-stretch max-h-screen overflow-hidden relative">
      <AdminDiscoveryForUser slug={slug} />{" "}
    </div>
  );
}

function PauseDiscoveryToggle({ id }: { id: string }): React.JSX.Element {
  const { data, loading } = useAdminDiscoveryIsPausedQuery({ variables: { id } });
  const [unpause] = useAdminUnpauseUserMutation();
  const [pause] = useAdminPauseUserMutation();
  const toggle = React.useCallback(
    async (newVal: boolean) =>
      newVal
        ? pause({ refetchQueries: ["AdminDiscoveryIsPaused"], variables: { id } })
        : unpause({ refetchQueries: ["AdminDiscoveryIsPaused"], variables: { id } }),
    [pause, unpause, id]
  );
  return (
    <SpinnerCheckbox
      onChange={() => toggle(!data?.discovery_paused_users_by_pk?.created_at)}
      checked={!loading && !!data?.discovery_paused_users_by_pk?.created_at}
    >
      Pause Discovery
    </SpinnerCheckbox>
  );
}

export function AdminDiscoveryForUser({ slug }: { slug: string }): React.JSX.Element {
  const [page, setPage] = React.useState<"feed" | "list">("feed");
  const summaryQuery = useAdminUserSummaryFromSlugQuery({
    skip: !slug,
    variables: { slug },
  });
  const user = summaryQuery.data?.admin_user_summaries?.[0];
  return (
    <>
      <div>
        {user?.id} / {user?.screen_name}
      </div>
      <div className={clsx("flex-0 tabs tabs-lifted tabs-lg")}>
        <button
          onClick={() => setPage("feed")}
          className={classNames("tab", page === "feed" && "tab-active")}
        >
          Feed
        </button>
        <button
          className={classNames("tab", page === "list" && "tab-active")}
          onClick={() => setPage("list")}
        >
          List
        </button>
      </div>
      {user &&
        (page === "feed" ? (
          <AdminDiscoveryUsersFeed user={user} />
        ) : (
          <AdminDiscoveryForUserPossibleMatches user={user} />
        ))}
    </>
  );
}

function AdminDiscoveryUsersFeed({
  user,
}: {
  user: Admin_User_Summary_FullFragment;
}): React.JSX.Element {
  const [feedType, setFeedType] = React.useState<L_News_Feed_Type>(L_News_Feed_Type.DiscoveryOnly);
  const genderLookup = useGenderLookup();
  const mkUrl = useMkImgAdminUrl();
  const query = useNewsFeedAdminQuery({
    skip: !user.id,
    variables: {
      filter: { feed_type: feedType },
      admin_user_id: user.id!,
    },
    fetchPolicy: "network-only",
  });
  return (
    <div className="space-y-2 max-h-full overflow-auto">
      <div className={clsx("max-w-md join flex flex-row items-center justify-center")}>
        {[
          { e: L_News_Feed_Type.ConnectionsOnly, t: "My Connections" },
          { e: L_News_Feed_Type.DiscoveryOnly, t: "Possible Matches" },
          { e: L_News_Feed_Type.StatusOnly, t: "Statuses" },
        ].map((item) => (
          <button
            onClick={() => setFeedType(item.e)}
            key={item.e}
            className={clsx(
              "join-item flex-1 btn btn-xs whitespace-nowrap btn-primary",
              feedType === item.e ? "" : "btn-outline "
            )}
          >
            {item.t}
          </button>
        ))}
      </div>
      {match(query)
        .with({ loading: true, data: P.nullish }, () => <SpinnerCentered />)
        .with({ loading: false, data: P.nullish }, () => <div>could not load data</div>)
        .with({ data: { l_news_feed: { __typename: "L_Simple_Response_Error" } } }, ({ data }) => (
          <div>{data.l_news_feed.human_readable_error}</div>
        ))
        .with({ data: { l_news_feed: { __typename: "L_News_Feed_Bad_Cursor" } } }, () => (
          <div>bad cursor</div>
        ))
        .with({ data: { l_news_feed: { __typename: "L_News_Feed_Empty" } } }, () => (
          <div>empty</div>
        ))
        .with({ data: { l_news_feed: P.nullish } }, () => <div>null news feed</div>)
        .with(
          { data: { l_news_feed: { __typename: "L_News_Feed_Response_Success" } } },
          ({ data: { l_news_feed } }) => (
            <table className="table w-full table-xs max-h-full ">
              <thead className="z-30 top-0 sticky bg-base-100">
                <tr>
                  <th>from</th>
                  <th>disc info</th>
                  <th>created at</th>
                  <th>type</th>
                  <th>item data</th>
                </tr>
              </thead>
              <tbody>
                {[...l_news_feed.items, ...l_news_feed.items, ...l_news_feed.items].map((item) => (
                  <tr key={item.id}>
                    <AdminUserTd
                      slug={match(item)
                        .with({ owner_slug: P.select() }, (s) => s)
                        .otherwise(() => null)}
                    />
                    <td>
                      {match(item)
                        .with({ discovery_data: P.select() }, (d) => (
                          <span>
                            {distToLocale({ units: "m", dist: d?.user.distance_m })} {d?.user.age}{" "}
                            {genderLookup?.data?.[d?.user.gender_id ?? ""]?.name?.en_US}{" "}
                            {d?.partner && (
                              <span>
                                / {d?.partner?.age}{" "}
                                {genderLookup?.data?.[d?.partner.gender_id ?? ""]?.name?.en_US}
                              </span>
                            )}
                          </span>
                        ))
                        .otherwise(() => null)}
                    </td>
                    <DateTd date={item.created_at} />
                    <td>{item.__typename.replace("L_News_Feed_Item_", "").replace(/_/g, " ")}</td>
                    <td>
                      {match(item)
                        .with({ __typename: "L_News_Feed_Item_Event" }, (d) => d.event_slug)
                        .with({ __typename: "L_News_Feed_Item_Media" }, (item) => (
                          <Link to={adminRoutes.MEDIA.EDIT.buildPath({ media_id: item.id })}>
                            {/* <div className="h-12 overflow-hidden"> */}
                            <img className="h-8 object-contain" src={mkUrl(item.id, "thumb")} />
                            {/* </div> */}
                          </Link>
                        ))
                        .with(
                          { __typename: "L_News_Feed_Item_Media_Reaction" },
                          (d) => d.reactor_slug + ": " + d.reaction
                        )
                        .with({ __typename: "L_News_Feed_Item_Mention" }, () => null)
                        .with({ __typename: "L_News_Feed_Item_Message_Reaction" }, () => null)
                        .with({ __typename: "L_News_Feed_Item_Public_Event" }, (d) => d.event_slug)
                        .with({ __typename: "L_News_Feed_Item_Status" }, (item) => item.content)
                        .with({ __typename: "L_News_Feed_Item_Status_Reaction" }, () => null)
                        .exhaustive()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
        )
        .exhaustive()}
    </div>
  );
}

export function AdminDiscoveryForUserPossibleMatches({
  user,
}: {
  user: Admin_User_Summary_FullFragment;
}): React.JSX.Element {
  const user_id = user.id;
  const prefsQuery = useAdminDiscoveryPreferencesQuery({
    variables: { id: user_id ?? "" },
    skip: !user_id,
  });
  const genderLookup = useGenderLookup();
  const prefs = prefsQuery?.data?.discovery_preferences?.[0];
  const discoveryQuery = useAdminDiscoveryResultsQuery({
    variables: { user_id: user_id ?? "" },
    skip: !user_id,
  });
  const loading = discoveryQuery.loading;
  // const loading = summaryQuery.loading || discoveryQuery.loading;
  if (loading) {
    return <SpinnerFullScreen />;
  }
  if (!user || !user_id) {
    return <div>no user data</div>;
  }
  const discData = discoveryQuery?.data?.l_discovery;
  const age = Math.floor(
    user?.birthday ? DateTime.now().diff(DateTime.fromISO(user.birthday), "years").years : 0
  );
  const genders = sortBy(
    filterNulls(
      prefs?.genders.map((g) => {
        const genderPair = g.gender_id.split("+");
        return {
          g1: genderLookup?.data?.[genderPair[0]],
          g2: genderPair[1] ? genderLookup?.data?.[genderPair[1]] : undefined,
        };
      })
    ),
    (a) => (a.g1?.seq_no ?? 0) + (a.g2?.seq_no ?? 0)
  ).reverse();
  return (
    <>
      <H4>
        {age} / {user.location_description} / {user.gender_id}
      </H4>
      {user.id && (
        <div>
          <PauseDiscoveryToggle id={user.id} />
        </div>
      )}
      <p>
        My Preferences: max_dist: {prefs?.max_distance}, age range: {prefs?.min_age}-
        {prefs?.max_age},{" "}
      </p>
      <p>
        {genders.map((g) => g.g1?.name.en_US + (g.g2 ? ` & ${g.g2?.name.en_US}` : "")).join(", ")}
        {/* ?.map((g) => g.gender_id)
          ?.join(", ")
          .replace(/gender_/g, "")} */}
      </p>
      {match(discData)
        .with({ __typename: "L_Discovery_Response_Success" }, (discData) => (
          <>
            <p className="space-x-2">
              <span>{discData.couple_matches} Couple matches</span>
              <span>{discData.individual_matches} Single matches</span>
            </p>
            <div className=" w-full ">
              <table className="overflow-auto max-h-full table w-full table-zebra table-sm">
                <thead className="z-30 top-0 sticky bg-base-100">
                  <tr>
                    <th>avatar</th>
                    <th>username</th>
                    <th>distance</th>
                    <th>gender(s)</th>
                    <th>age</th>
                    <th>their preferred genders</th>
                    <th>their age range</th>
                    <th>their max dist</th>
                  </tr>
                </thead>
                <tbody>
                  {discData.items?.map((item, idx) => (
                    <DiscoveryItemRow
                      myPrefs={prefs}
                      myAge={age}
                      myGender={user.gender_id ?? ""}
                      key={idx}
                      user_id={user_id}
                      item={item}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ))
        .with(P.nullish, () => <div>no discovery data</div>)
        .with({ __typename: "L_Discovery_Response_Error" }, (discData) => (
          <div> error : {discData.error_type}</div>
        ))
        .with({ __typename: "L_Discovery_Response_Empty" }, () => <div> empty </div>)
        .with({ __typename: "L_Discovery_Response_Waitlist" }, () => <div> waitlisted </div>)
        .with({ __typename: "L_Discovery_Response_Not_Ready_Yet" }, (discData) => (
          <div>
            <div>Not ready yet. Missing: {discData.missing_fields?.join(", ")}</div>
          </div>
        ))
        .exhaustive()}
    </>
  );
}
