import {
  GqlOps,
  Onboarding_Statuses_Enum,
  useAdminUserListQuery,
  useAdminUserOnboardingStatusQuery,
  useRemoveManualVerificationMutation,
  useSetOnboardingStatusMutation,
  useSetVerifiedMutation,
} from "../__generated__/apollo-hooks";
import { Link, NavLink, NavLinkProps } from "react-router-dom";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "shared-web-react/dist/widgets/floating-ui/tooltip";
import { faDown, faUp } from "@fortawesome/pro-solid-svg-icons";
import { filterNulls, toEnum } from "shared/dist/util";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { Combobox } from "@headlessui/react";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import { UserIdSlugSn } from "./types";
import { adminRoutes } from "./admin-routes";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { faBadgeCheck } from "@fortawesome/pro-solid-svg-icons";
import { match } from "ts-pattern";
import { useDebounce } from "use-debounce";
import { useFloating } from "@floating-ui/react";
import { useMyId } from "shared/dist/auth-data";

type AdminUserPickerProps = {
  onSelect: (d: null | UserIdSlugSn) => void;
  inputClassName?: React.AllHTMLAttributes<HTMLDivElement>["className"];
};
export function AdminUserPicker({
  onSelect,
  inputClassName,
}: AdminUserPickerProps): React.JSX.Element {
  const [selectedSlug, setSelectedSlug] = React.useState<null | UserIdSlugSn>(null);
  const { refs, floatingStyles } = useFloating({
    placement: "bottom-start",
  });

  const [filterString, setFilterString] = React.useState("");
  const [debouncedFilterString] = useDebounce(filterString, 300);
  const { data } = useAdminUserListQuery({
    variables: {
      filter: `%${debouncedFilterString}%`,
      loc_filter: `%${debouncedFilterString}%`,
      limit: 20,
    },
  });
  const wrappedOnSelect = (e: UserIdSlugSn | null) => {
    setSelectedSlug(e);
    onSelect(e);
  };
  const filteredPeople = filterNulls(
    filterString === ""
      ? data?.admin_user_summaries
      : data?.admin_user_summaries?.filter((person) => {
          return (
            // person?.location_description?.toLowerCase()?.includes(filterString.toLowerCase()) ||
            person?.slug?.toLowerCase()?.includes(filterString.toLowerCase()) ||
            person?.screen_name?.toLowerCase()?.includes(filterString.toLowerCase()) ||
            person?.email?.toLowerCase()?.includes(filterString.toLowerCase())
          );
        })
  );

  return (
    <Combobox onChange={wrappedOnSelect} value={selectedSlug}>
      <Combobox.Input
        ref={refs.setReference}
        className={clsx(
          selectedSlug && "bg-base-200",
          "input input-bordered input-info",
          inputClassName
        )}
        value={selectedSlug?.slug ? `@${selectedSlug.slug}` : filterString}
        onChange={(event) => {
          setFilterString(event.target.value);
          setSelectedSlug(null);
        }}
      />
      {createPortal(
        <Combobox.Options
          ref={refs.setFloating}
          style={floatingStyles}
          className={clsx(
            !filteredPeople.length && "hidden",
            "menu bg-primary text-primary-content  absolute rounded-box z-30"
          )}
        >
          {filteredPeople.map((person) => (
            <Combobox.Option
              key={person.id}
              value={{ slug: person.slug, id: person.id, screenName: person.screen_name }}
              className={clsx("")}
            >
              <span>
                {"@" + person.slug}{" "}
                {/* <span className={clsx("italic")}>{person.location_description}</span> */}
              </span>
            </Combobox.Option>
          ))}
        </Combobox.Options>,
        document.body
      )}
    </Combobox>
  );
}

export type AdminTabProps = NavLinkProps;
export function AdminTab({ className, ...props }: AdminTabProps): React.JSX.Element {
  return (
    <NavLink
      {...props}
      className={(args) =>
        clsx(
          "tab",
          args.isActive && "tab-active font-bold",
          typeof className === "function" ? className(args) : className
        )
      }
    />
  );
}

export type AdminVerificationToggleProps = {
  user_id: string;
  status?: boolean;
  source?: string;
};
export function AdminVerificationToggle({
  status,
  user_id,
  source,
}: AdminVerificationToggleProps): React.JSX.Element {
  const [mutate, { loading }] = useSetVerifiedMutation();
  const [removeMutation, { loading: removeLoading }] = useRemoveManualVerificationMutation();
  const my_id = useMyId();
  const anyLoading = loading || removeLoading;
  const removeVerification = async () => {
    if (!my_id) {
      return;
    }
    const confirmation = confirm("Are you sure you want to remove verification");
    if (!confirmation) {
      return;
    }
    removeMutation({
      refetchQueries: ["AdminUserList"],
      variables: {
        user_id,
      },
    });
  };
  const setVerified = async () => {
    if (!my_id) {
      return;
    }
    console.log("🚀 ~ file: widgets.tsx:86 ~ setVerified ~ my_id:", my_id);
    mutate({
      refetchQueries: ["AdminUserList"],
      variables: {
        user_id,
        data: { admin_user: my_id, extra: "done from admin panel" },
      },
    });
  };
  if (status) {
    return source === "manual" ? (
      <button
        data-tip={`click to revoke`}
        onClick={() => removeVerification()}
        disabled={anyLoading}
        className={clsx("btn btn-sm btn-accent w-full text-center tooltip ")}
      >
        <FontAwesomeIcon icon={faBadgeCheck} fixedWidth />
      </button>
    ) : (
      <div data-tip={`source: ${source} `} className={clsx("w-full text-center tooltip")}>
        <FontAwesomeIcon icon={faBadgeCheck} fixedWidth />
      </div>
    );
  }
  return (
    <button
      onClick={() => setVerified()}
      disabled={anyLoading}
      className={clsx("btn btn-warning btn-xs")}
    >
      Verify
    </button>
  );
}

const Statuses = Onboarding_Statuses_Enum;
export function AdminOnboardingStatusToggle({ user_id }: { user_id: string }): React.JSX.Element {
  const [mutate, { loading: mutationLoading }] = useSetOnboardingStatusMutation();
  const { data, loading: queryLoading } = useAdminUserOnboardingStatusQuery({
    variables: { user_id },
    fetchPolicy: "cache-and-network",
  });

  const obStatus = toEnum(
    Onboarding_Statuses_Enum,
    data?.admin_user_summaries?.[0]?.onboarding_status
  );

  const setObVerification = React.useCallback(async () => {
    if (!user_id) return;
    if (obStatus !== Statuses.V2_10ObCompletePendingApproval && obStatus !== Statuses.V2_20LiveUser)
      return;

    return mutate({
      refetchQueries: [GqlOps.Query.AdminUserOnboardingStatus],
      variables: {
        user_id,
        status:
          obStatus === Statuses.V2_10ObCompletePendingApproval
            ? Statuses.V2_20LiveUser
            : Statuses.V2_10ObCompletePendingApproval,
      },
    });
  }, [mutate, obStatus, user_id]);
  if (!obStatus) return <></>;

  const loading = mutationLoading || queryLoading;
  return (
    <Tooltip>
      <TooltipTrigger>
        <SpinnerButton
          onClickWrapped={() => setObVerification()}
          loading={loading}
          size="sm"
          disabled={
            loading ||
            match(obStatus)
              .with(Statuses.V2_20LiveUser, Statuses.V2_10ObCompletePendingApproval, () => false)
              .otherwise(() => true)
          }
          className={clsx("text-center tooltip ")}
        >
          <div
            className={
              obStatus === Statuses.V2_10ObCompletePendingApproval ? "text-white" : "text-primary"
            }
          >
            {match(obStatus)
              .with(Statuses.V2_00New, () => "new user")
              .with(Statuses.V2_01PendingEventUser, () => "Incomplete User From Event")
              .with(Statuses.V2_01NewEventUser, () => "Event User")
              .with(Statuses.V2_05InvitedConfirmedObPending, () => "Invited, needs OB")
              .with(Statuses.V2_10ObCompletePendingApproval, () => "Pending Approval")
              .with(Statuses.V2_20LiveUser, () => "Live User")
              .with(Statuses.V2_25PausedUser, () => "Paused User")
              .exhaustive()}
          </div>
        </SpinnerButton>
      </TooltipTrigger>
      <TooltipContent>User status: {obStatus}</TooltipContent>
    </Tooltip>
  );
}

export function IdSpan({
  id,
  size,
  className,
}: {
  id: null | undefined | string;
  size?: "xs" | "sm" | "md" | "lg";
  className?: string;
}): React.JSX.Element {
  return (
    <Tooltip>
      <TooltipTrigger>
        <span
          className={clsx(
            "inline-block overflow-hidden overflow-ellipsis whitespace-nowrap",
            match(size)
              .with("xs", () => "max-w-[8em]")
              .with("sm", () => "max-w-[12em]")
              .with("md", () => "max-w-[16em]")
              .with("lg", () => "max-w-[24em]")
              .otherwise(() => "max-w-[8em]"),
            className
          )}
        >
          {id ?? ""}
        </span>
      </TooltipTrigger>
      <TooltipContent>{id ?? "<missing id>"}</TooltipContent>
    </Tooltip>
  );
}

export type ColHeaderProps<T> = {
  value: keyof T;
  field: keyof T;
  setField: (t: keyof T) => void;
  setReverse: (t: boolean) => void;
  reverse?: boolean;
  label?: string;
};

export function ColHeader<T>({
  value,
  setReverse,
  reverse,
  field,
  label,
  setField,
}: ColHeaderProps<T>): React.JSX.Element {
  return (
    <th
      className={clsx(value === field ? "font-bold underline" : "")}
      onClick={() => {
        setReverse(!reverse);
        setField(value);
      }}
    >
      {String(label ?? value)}
      {value === field ? (
        <FontAwesomeIcon className={clsx("mx-1 ")} icon={reverse ? faDown : faUp} />
      ) : (
        ""
      )}
    </th>
  );
}

const isString = (value: any): value is string => typeof value === "string";

const isDate = (value: any): value is Date => value instanceof Date && !isNaN(value.getTime());

export function useDateStr(date: Date | DateTime | string | null | undefined): string {
  return React.useMemo(() => {
    const parsedDt = !date
      ? null
      : isString(date)
        ? DateTime.fromISO(date)
        : isDate(date)
          ? DateTime.fromJSDate(date)
          : date;
    return parsedDt?.toLocaleString(DateTime.DATETIME_SHORT) ?? "<null date>";
  }, [date]);
}

export function fmtDt(date: Date | DateTime | string | null | undefined) {
  const parsedDt = !date
    ? null
    : isString(date)
      ? DateTime.fromISO(date)
      : isDate(date)
        ? DateTime.fromJSDate(date)
        : date;
  return parsedDt?.toLocaleString(DateTime.DATETIME_SHORT) ?? "<null date>";
}

export function DateTd({
  date,
}: {
  date: Date | DateTime | string | null | undefined;
}): React.JSX.Element {
  const dtStr = useDateStr(date);
  return <td className="whitespace-nowrap">{dtStr}</td>;
}

export function AdminUserAvatar({ slug }: { slug: string | null | undefined }): React.JSX.Element {
  return slug ? (
    <Link
      className="flex flex-row gap-1 justify-start items-center"
      to={adminRoutes.USERS.DETAIL.buildPath({
        slug: slug,
      })}
    >
      <Avatar tailwindSize="8" slug={slug} className="mr-1" />
      <span className={clsx("text-ellipsis inline-block overflow-hidden max-w-[24ch]")}>
        {"@" + slug}
      </span>
    </Link>
  ) : (
    <span className="italic text-warning">missing user</span>
  );
}

export function AdminUserTd({ slug }: { slug: string | null | undefined }): React.JSX.Element {
  return (
    <td>
      <AdminUserAvatar slug={slug} />
    </td>
  );
}
